<template>
    <div class="col">
        <div class="bg-white p-4">
            <h3>Documenten</h3>
            <table class="table">
                <thead>
                    <tr>
                        <th>Toegankelijk</th>
                        <th>Bestands naam</th>
                        <th>geüpload door</th>
                        <th>Upload datum</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="file in dossier.files" :key="file.id">
                        <td>
                            <span v-if="file.user_id != null">
                                <input type="checkbox" v-model="file.is_public" @change="updateFileVisibility(file)" :true-value="1" :false-value="0"/>
                            </span>
                        </td>
                        <td>
                            <a href="#" @click.prevent="downloadDocument(file)">{{file.name}} </a>
                        </td>
                        <td>
                            <span v-if="file.contact_id != null"> By Contact </span>
                            <span v-else-if="file.user_id != null"> By User </span>
                        </td>
                        <td>
                            <small>{{formatDate(file.created_at, 'fromNow')}}</small>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import dossierservice from '@/services/admin/DossierService'
    import pdfImage from "@/assets/pdf.png"

    export default {
        name: 'Documents',
        props: {
            dossier: {}
        },
        data() {
            return {
                pdfImage: pdfImage,
                files: []
            }
        },
        methods: {
            downloadDocument(file) {
                dossierservice.downloadFile(this.dossier.id, file.id).then(response => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', file.name);
                    document.body.appendChild(link);
                    link.click();
                }).catch(error => {
                    this.$toast.success('Kan document niet downloaden')
                });
            },
            updateFileVisibility(file) {
                dossierservice.updateFileVisibility(file).then(response => {
                    this.$toast.success('Bestand status is aangepast')
                }).catch(error => {
                    this.$toast.success('Update mislukt')
                })
            }
        }
    }
</script>