
<template>
    <div class="row">
        <div class="col">
            <div class="bg-white p-4 pt-2">
                <!--<h3>Quick links</h3>
                Cliënt: <span v-if="dossier.contact.gender == 'm'"> De heer </span>
                <span v-else-if="dossier.contact.gender == 'v'"> Mevrouw </span>
                <span v-else></span>
                {{dossier.contact.initials}}<template v-if='dossier.contact.prefix'> {{dossier.contact.prefix}}</template> {{dossier.contact.last_name}}<br><br>
                <a :href="'tel:'+dossier.contact.phone_number" class="btn btn-primary" style="width: 100%;">Bel {{dossier.contact.phone_number}}</a>
                <br/><br/>-->
                <div class="btn btn-primary" @click="editRequest(false)">Wijzig uitgangspunten</div>
                <div class="btn btn-warning" @click="editRequest(true)">Kopieer dossier</div>
                <a class="btn btn-success" :href="'https://appletree.efficy.cloud/crm/consult?entity=Proj&page=desktop/Consult&detail=DEFAULT&key='+dossier.efficy_K_PROJECT" target="_blank">Open project in Efficy</a>
                <div class="btn btn-warning" @click="loginAsContact">Inloggen Mijn Apple Tree (Cliënt)</div>
                <div class="btn btn-danger" @click="openModal(true)">Wijzig wachtwoord</div>
            </div>
        </div>
    </div>

    
  <div class="modal" :class="{'d-block' : showPasswordModal}" tabindex="-1" aria-modal="true" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <dialog open="" class="modal-content p-0">
        <div class="modal-body">
          <p>Password aanpassen voor: {{dossier.contact.email}}.</p>
          <div class="loginfield">
            <label for="contactpassword">Nieuw wachtwoord voor Contact</label>
            <input class="form-control" type="text" name="contactpassword" v-model="formData.contact_password" />
            <br>
            <label for="password">Gebruikers wachtwoord voor controle</label>
            <input class="form-control" type="password" name="password" v-model="formData.password" />
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-success" @click="setContactPassword" :disabled="!formData.contact_password || !formData.password">Wachtwoord aanpassen</button>
          <button type="button" class="btn btn-secondary" @click="openModal(false)">Annuleren</button>
        </div>
      </dialog>
    </div>
  </div>
</template>

<script>
import dossierservice from '@/services/admin/DossierService'
import loginservice from '@/services/admin/LoginService'

export default {
    name: 'QuickLinks',
      data() {
         return {
            formData: {
               'password': '',
               'contact_password': '',
            },
            'showPasswordModal': false
         }
      },
    props: { 
        dossier: {}
    },
    methods: {
      openModal(option){
        this.formData.password = ''
        this.formData.contact_password = ''
        this.showPasswordModal = option
      },
      editRequest(copyDossier = false) {
        let windowRef = window.open("about:blank", "_blank");
        dossierservice.createExternalDataToken(this.dossier.id, copyDossier).then(response => {
          windowRef.location.href = response.data;
          windowRef.focus();
        }).catch(error => {
          this.$toast.error('Failed to create external data link')
          windowRef.close()
        })
      },

      loginAsContact() {
        loginservice.contactLogin(this.dossier.contact.id).then(response => {
          const route = process.env.VUE_APP_CLIENT_URL + '?ulogin=true&email='+encodeURIComponent(response.data.email)+'&name='+encodeURIComponent(response.data.name)+'&role=contact'+'&token='+encodeURIComponent(response.data.token)
          window.open(route, '_blank')
        }).catch(error => {
          console.log(error)
          this.$toast.error('Fout bij inloggen')
        })
      },

      setContactPassword() {
        loginservice.setContactPassword({'password': this.formData.password, 'contact_password': this.formData.contact_password}, this.dossier.contact_id).then(response => {
          console.log(response.data)
          if(response.data == true) {
            this.$toast.success('Success')
            this.openModal(false)
          } else {
            this.$toast.error('Error')
          }
        }).catch(error => {
          this.$toast.error('Error')
        })
      },
      
    },
}
</script>
<style scoped>
  .btn {
    width: 100%;
    margin-top: 15px
  }
  .passwordModal{
    display: none;
  }
  .show{
    display: block;
  }
</style>