<template>
    <div class="row mt-4">
        <div class="col">
            <div class="bg-white p-4">
                <h3>Voortgang</h3>
                <div class="timeline vertical block mb-4" :data-current-step-number="contactStep">
                    <div class="tl-item" v-for="step in allSteps" :key="step.id" :data-this-step-number="step.id + ' - ' + step.number"
                        :class="{ 'active': contactStep === step.id, 'completed': contactStep > step.id || ((step.number == 62 || step.number == 61) &&  contactStep == step.id)}" @click="manuallyChangeStep(step.id)">
                        <div class="tl-dot" :class="getLogClass(step.should_show)"></div>
                        <div class="tl-content">
                            <div><strong>{{step.actor}}: </strong> {{step.action}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import dossierservice from '@/services/admin/DossierService'

    export default {
        name: 'ContactSteps',
        props: {
            contactStep: {}
        },
        data() {
            return {
                allSteps: []
            }
        },
        methods: {
            getAllSteps() {
                dossierservice.getDossierSteps().then(response => {
                    this.allSteps = response.data
                }).catch(error => {
                    console.log('error', error) // create notification-toaster for user error
                })
            },
            manuallyChangeStep(stepId) {
                if (confirm('Wijzig stap?')) {
                    dossierservice.manuallyChangeStep(this.$route.params.id, stepId).then(response => {
                        this.$parent.dossier.dossier_step_id = stepId
                        this.$toast.success('Stap is aangepast.')
                    }).catch(error => {
                        console.log('error', error) // create notification-toaster for user error
                    })
                }
            },
            getLogClass(isVisibleToContact) {
                if (isVisibleToContact) {
                    return 'b-success'
                }
                return 'b-primary'
            },
        },
        mounted() {
            this.getAllSteps()
        }
    }
</script>