import {ApiService} from '@/services/ApiService'

const URL = '/api/admin/pension-company'
class PensionCompanyService extends ApiService  {
  constructor(){
    super()
  }
  getUrl(){
      return URL
  }
}

export default new PensionCompanyService()