<template>
  <div>
    <div class="row">
      <!-- LEFT SIDEBAR -->
      <div class="col-md-8 accordion" id="dossier-accordion" v-if="dossier">
        <h4 class="mb-0">Dossier #{{dossier.dossier_number}}&ensp;&mdash;&ensp;
        {{dossier.contact.addressed_name}}
      </h4>
      
      <template v-if="dossier.pension_type != 'variabel'">
      
        <Accordion :index="1">
          <template #header>
            1 - Koppel adviseur
          </template>
          <template #body>
            <div class="col bg-white p-1">
              <div class="row">
                <label for="user" class="col-3 mt-2"> Adviseur </label>
                <select class="w-50 form-select" v-model="dossier.user_id" id="user">
                  <option :value="null">Selecteer...</option>
                  <template v-for="user in users" v-bind:key="user.id">
                    <option :value="user.id">{{getName(user)}}</option>
                  </template>
                </select>
              </div>
              <div class="row mt-2">
                <div class="col-3"></div>
                <button class="col-auto px-3 btn btn-success" @click="coupleUserToDossier"> Toewijzen en informeren </button>
              </div>
            </div>
          </template>
        </Accordion>

        <Accordion :index="2" >
          <template #header>
            2 - Controleer uitkeringen
          </template>
          <template #body>
            <Quotes v-if="dataLoaded && dossier" :dossier="dossier" />
          </template>
        </Accordion>

        <Accordion :index="3" >
          <template #header>
            3 - Controleer factuurbedrag
          </template>
          <template #body>
            <div class="col bg-white p-1">
              <div class="row">
                <label for="source_price" class="col-3 mt-2"> Factuurbedrag (€) </label>
                <input class="w-50 form-control number" v-model="dossier.source_price" id="source_price" v-on:keyup="dossier.source_price = dossier.source_price.replace(/[^0-9,.]/g, '').replace(/[,]/g, '.')" @change="dossier.source_price = new Intl.NumberFormat('en', {useGrouping: false, minimumFractionDigits: 2, maximumFractionDigits: 2}).format(dossier.source_price)"/>
              </div>
              <div class="row mt-1">
                <div class="col-3"></div>
                <div class="col-auto p-0"> 
                  <!--<template v-if="dossier.source.source_charge_contact == 0">
                    <input type="checkbox" id="not-extra" class="form-check-input" v-model="dossier.source.source_charge_contact" :true-value="0" :false-value="1" :disabled="true"/>
                  </template>
                  <template v-else>-->
                    <input type="checkbox" id="not-extra" class="form-check-input" v-model="dossier.source_charge_contact" :true-value="0" :false-value="1"/>
                  <!--</template>-->
                  
                  <label for="not-extra" class="ms-2"> Niet aan cliënt doorbelasten </label>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-3"></div>
                <div class="col-auto btn btn-success" @click="updateDossier"> Opslaan </div>
              </div>
            </div>
          </template>
        </Accordion>

        <div class="row mt-4" v-if="false">
          <Documents v-if="dataLoaded && dossier" :dossier="dossier" />
        </div>
          
        <Accordion :index="4">
          <template #header>
            4 - Download en e-mail offerte
          </template>
          <template #body>
              <div class="row mt-1">
                <label for="additional_text_base" class="col-3 mt-2"> Tekst boven uitgangspunten </label>
                <textarea class="w-50 form-control" v-model="dossier.additional_text_base" id="additional_text_base"/>
              </div>
              <div class="row mt-1">
                <label for="additional_text_costs" class="col-3 mt-2"> Tekst boven kosten </label>
                <textarea class="w-50 form-control" v-model="dossier.additional_text_costs" id="additional_text_costs"/>
              </div>
              <div class="row mt-1">
                <label for="additional_text_costs" class="col-3 mt-2"> Efficy <strong>dossier</strong> key </label>
                <input class="w-50 form-control" v-model="dossier.efficy_K_PROJECT" id="efficy_K_PROJECT"/>
              </div>
              <div class="row mt-1">
                <label for="additional_text_costs" class="col-3 mt-2"> Efficy <strong>contact</strong> key </label>
                <input class="w-50 form-control" v-model="dossier.contact.efficy_K_CONTACT" id="efficy_K_CONTACT"/>
              </div>
              <div class="row mt-1">
                <div class="col-3"></div>
                <div class="col-auto btn btn-success" @click="updateDossier"> Opslaan </div>
              </div>
              <br/>

              <div class="bg-white p-1">
                <button type="button" class="btn btn-primary position-relative" style="width: 50%;margin: auto;display:block;" @click="downloadGeneratedOfferte">
                  <i class="float-start fa fa-empty position-absolute" style="left: 1rem;bottom: 0.6rem"></i>Download offerte
                </button>
                <br>
                <button class="btn btn-secondary" :class="{'btn-outline-secondary': (!dossier.user || dossier.quotation_mail_send)}" style="width: 50%;margin: auto;display:block;" @click="approveDossier" :disabled="!dossier.user || dossier.quotation_mail_send">
                     <template v-if="dossier.quotation_mail_send"><i class="fa fa-check"></i> Offerte verstuurd op {{formatDate(dossier.email_send_date, 'dateTime')}}</template>
                     <template v-else><i class="fa fa-empty"></i> E-mail offerte ({{dossier.contact.email}})</template>
                </button>
                <br>

                <div class="btn btn-warning" style="width: 50%;margin: auto;display:block;" @click="loginAsContact">Inloggen Mijn Apple Tree (Cliënt)</div>
              </div>
          </template>
        </Accordion>

        <Accordion :index="5" v-if="dossier.files && dossier.files.length > 0">
          <template #header>
            5 - Documenten
          </template>
          <template #body>
            <div class="row mt-1">
                <div class="col-1"></div>
                <div class="col-8">Type</div>
                <div class="col-3">Datum</div>
              </div>
              <hr>
              <div class="row mt-1" v-for="file in dossier.files" :key="file.id">
                <div class="col-1">
                  <input type="checkbox" class="form-check-input" v-model="file.selected" :true-value="1" :false-value="0"/>
                </div>
                <div class="col-8">
                    <template v-if="file.content_type == 'full_document'">
                      <a href="#heading-5" @click="downloadUserUploadedFile(file.id, file.content_type, file.file_type, file.name)">
                        {{file.name}}
                      </a>
                    </template>
                    <template v-else>
                      <a href="#heading-5" @click="downloadUserUploadedFile(file.id, file.content_type, file.file_type)">
                        {{ fileCategories[file.content_type] }}
                        {{((file.content_type == 'identification' || file.content_type == 'identificationPartner') ? (' - ' + file.name) : '') }}
                      </a>
                    </template>
                </div>
                <div class="col-3">
                  {{ formatDate(file.created_at, 'shortDate') }}
                </div>
              </div>
              <div class="row mt-1">
                <div class="col-1">
                  <input type="checkbox" class="form-check-input" v-model="send_quotation" :true-value="1" :false-value="0"/>
                </div>
                <div class="col-8">
                  <a href="#heading-5" @click="downloadGeneratedOfferte">Apple Tree offerte {{ dossier.contact.full_name }}</a>
                </div>
                <div class="col-3">
                  {{ formatDate(dossier.email_send_date, 'shortDate') }}
                </div>
              </div>
              <div class="row mt-1">
                <div class="col-1">
                  <input type="checkbox" class="form-check-input" v-model="send_company_quotation" :true-value="1" :false-value="0"/>
                </div>
                <div class="col-8">
                  <a href="#heading-5" @click="downloadQuoteFile(this.selectedQuote.id, this.selectedQuote.company_quote_file_name)">{{selectedQuote.provider}} offerte</a>
                </div>
                <div class="col-3">
                  {{ formatDate(dossier.dossier_accepted_date, 'shortDate') }}
                </div>
              </div>
              <div class="row mt-1" v-if="dossier.invoice_id">
                <div class="col-1">
                  <input type="checkbox" class="form-check-input" v-model="send_invoice" :true-value="1" :false-value="0"/>
                </div>
                <div class="col-8">
                  <a href="#heading-5" @click="downloadGeneratedInvoice">Factuur {{ dossier.invoice.invoice_number }}</a>
                </div>
                <div class="col-3">
                  {{ formatDate(dossier.dossier_accepted_date, 'shortDate') }}
                </div>
              </div>
              <div class="row mt-1" v-if="dossier.pension_fund_history">
                <div class="col-1">
                  <input type="checkbox" class="form-check-input" v-model="send_aanvraag" :true-value="1" :false-value="0"/>
                </div>
                <div class="col-8">
                  <a href="#heading-5" @click="downloadGeneratedSigned('aanvraag_formulier')">Aanvraagformulier (ondertekend)</a>
                </div>
                <div class="col-3">
                  {{ formatDate(dossier.pension_fund_history[0].created_at, 'shortDate') }}
                </div>
              </div>
              <div class="row mt-1" v-if="dossier.lijfrente_type == null && dossier.partner && dossier.partner.last_name != null && dossier.partner.insure_partner_pension == 0 && 
                                        (!dossier.pension_fund_history[0] || dossier.pension_fund_history[0].origin != 'Gouden Handdruk')">
                <div class="col-1">
                  <input type="checkbox" class="form-check-input" v-model="send_afstand" :true-value="1" :false-value="0"/>
                </div>
                <div class="col-8">
                  <a href="#heading-5" @click="downloadGeneratedSigned('afstandsverklaring')">Afstandsverklaring</a>
                </div>
                <div class="col-3">
                  {{ dossier.invoice ? formatDate(dossier.dossier_accepted_date, 'shortDate') : '-' }}
                </div>
              </div>
              <div class="row mt-1" v-for="fund in dossier.pension_fund_history" :key="fund.id">
                <div class="col-1">
                  <input type="checkbox" class="form-check-input" v-model="fund.selected" :true-value="1" :false-value="0"/>
                </div>
                <div class="col-8">
                  <a href="#heading-5" @click="downloadGeneratedSigned('verzoek_tot_overmaken', fund.pension_company.name, fund.id)">Verzoek tot overmaken {{fund.pension_company.name}} ({{fund.polis_number}})</a>
                </div>
                <div class="col-3">
                  {{ formatDate(dossier.dossier_accepted_date, 'shortDate') }}
                </div>
              </div>

              <div class="d-flex justify-content-end mt-4">
                <button type="button" class="btn btn-success" @click="exportEfficy" :disabled="isSyncing">Synchroniseer documenten met Efficy</button>
              </div>
          </template>
        </Accordion>

         <Accordion :index="6" v-if="dataLoaded && dossier && dossier.dossier_step_id >= 25 && dossier.files && dossier.files.length && dossier.accepted_quote_date && showSettlement">
          <template #header>
            6 - Afhandeling aanvraag
          </template>
          <template #body>
            <Settlement :dossier="dossier" :selectedQuote="selectedQuote" :canEdit="true" />
          </template>
        </Accordion>

      </template>
      <div class="row" v-else>
        <p>Variabele dossiers momenteel niet beschikbaar op Apple Tree Portaal.</p>
      </div>
    </div>

      <!-- RIGHT SIDEBAR -->
      <div class="col-md-4">
        <!-- DETAILS -->
        <section class="right-sidebar">
          <div class="row">
            <div class="col">
              <div class="bg-white p-4">
                <h3>Uitgangspunten</h3>
                <table>
                  <template v-if="dossier.contact">
                    <tr>
                      <th>Verzekerde</th>
                      <td>{{dossier.contact.addressed_name}}
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>{{formatDate(dossier.contact.birth_date, 'shortDate') + ' ' + calcAge(dossier.contact.birth_date)}}</td>
                    </tr>
                    <template v-if='dossier.contact.address'>
                      <tr>
                        <td></td>
                        <td>{{dossier.contact.address.street}} {{dossier.contact.address.house_number}}</td>
                      </tr>
                      <tr>
                        <td></td>
                        <td>{{dossier.contact.address.postal_code}} {{dossier.contact.address.city}}</td>
                      </tr>
                    </template>
                    <tr>
                      <td></td>
                      <td><a :href="'tel:'+dossier.contact.phone_number">{{dossier.contact.phone_number}}</a></td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>{{dossier.contact.email}}</td>
                    </tr>
                    <br>
                  </template>
                  <template v-if="dossier.has_partner && dossier.partner">
                    <tr>
                      <th>Partner</th>
                      <td>{{dossier.partner.addressed_name}}
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td v-if="dossier.partner.birth_date && dossier.partner.birth_date != null">{{formatDate(dossier.partner.birth_date, 'shortDate') + ' ' + calcAge(dossier.partner.birth_date)}}</td>
                      <td v-else></td>
                    </tr>
                    <template v-if='dossier.partner.address'>
                      <tr>
                        <td></td>
                        <td>{{dossier.partner.address.street}} {{dossier.partner.address.house_number}}</td>
                      </tr>
                      <tr>
                        <td></td>
                        <td>{{dossier.partner.address.postal_code}} {{dossier.partner.address.city}}</td>
                      </tr>
                    </template>
                    <tr v-if="dossier.partner_death_benefit_target">
                      <th>Overgang</th>
                      <td>{{overgang[dossier.partner_death_benefit_target]}}</td>
                    </tr>
                    <tr v-if="dossier.partner_death_benefit">
                      <th>Uitkering bij overgang</th>
                      <td>{{dossier.partner_death_benefit}}%</td>
                    </tr>
                    <tr>
                      <th>Meeverzekerd</th>
                      <td>{{dossier.partner.insure_partner_pension ? 'Ja' : 'Nee'}}</td>
                    </tr>
                    <br>
                  </template>
                  <template v-else>
                    <tr>
                      <th>Partner</th>
                      <td>Geen partner</td>
                    </tr>
                    <br>
                  </template>
                  <tr v-if="dossier.source">
                    <th>Aanvraag vanuit</th>
                    <td>{{dossier.source.name}}</td>
                  </tr>
                  <tr v-if="dossier.pension_fund_history">
                    <th>Koopsom</th>
                    <td>
                      {{formatCurrency(totalPension, '€', 0)}} <br>
                    </td>
                  </tr>
                  <tr>
                    <th>Ingangsdatum</th>
                    <td>{{formatDate(dossier.start_date_payroll, 'shortDate')}}</td>
                  </tr>
                  <tr>
                    <th>Einddatum</th>
                    <td>{{dossier.end_date_payroll ? formatDate(dossier.end_date_payroll, 'shortDate') : 'Levenslang'}}</td>
                  </tr>
                  <tr v-if="dossier.pension_fund_history && dossier.pension_fund_history[0]">
                    <th>Fiscale herkomst</th>
                    <td class="capitalize">{{dossier.pension_fund_history[0].origin}}</td>
                  </tr>
                  <tr>
                    <th>Uitkeringstype</th>
                    <td class="capitalize">{{dossier.pension_type ? dossier.pension_type : '-'}}</td>
                  </tr>
                  <tr v-show="dossier.payroll_terms != 0">
                    <th>Uitkering per</th>
                    <td>{{formatMonthPeriod(dossier.payroll_terms, false)}}</td>
                  </tr>
                  <tr >
                    <th>Aanvraag datum</th>
                    <td>{{formatDate(dossier.created_at, 'shortDate')}}</td>
                  </tr>
                  <tr >
                    <th>Geregistreerd op</th>
                    <td><span v-if="dossier.contact.email_verified_at">{{formatDate(dossier.contact.email_verified_at, 'shortDate')}}</span><span v-else>-</span></td>
                  </tr>
                  <tr >
                    <th>Laatste update</th>
                    <td>{{formatDate(dossier.updated_at, 'shortDate')}}</td>
                  </tr>

                  <tr v-show="dossier.pension_type_high_years != 0">
                    <th>Periode hoog/laag</th>
                    <td>{{dossier.pension_type_high_years}} jaar</td>
                  </tr>

                </table>

              </div>
            </div>
          </div>

          <QuickLinks :dossier="dossier" />
          <!-- <ContactSteps :contactStep="dossier.dossier_step_id === 8 && dossier.quotes.find(element => element.active == true && element.selected == true) ? 7 : dossier.dossier_step_id" /> -->
          <ContactSteps :contactStep="dossier.dossier_step_id" />
          <!--<ContactActivities :logs="dossier.logs"/>-->
          <template v-if="dossier.siblings && dossier.siblings.length > 1">
            <div class="row">
              <div class="col">
                <div class="bg-white p-4">
                  <h3>Gerelateerde dossiers</h3>
                  <table id="relatedTable">
                      <tr>
                        <th>Dossier nr</th>
                        <th>Koopsom</th>
                        <th>Fiscale herkomst</th>
                        <th>Datum</th>
                        <th></th>
                      </tr>
                      <tr v-for="childDossier in dossier.siblings" :key="childDossier.id" :class="{'current-dossier': childDossier.id == dossier.id}">
                        <td>
                          <router-link :to="`/dossier/${childDossier.dossier_number}`">
                              {{childDossier.dossier_number}}
                          </router-link>
                        </td>
                        <td>{{formatCurrency(totalOtherPension(childDossier.pension_fund_history), '€', 0)}}</td>
                        <td><pre>{{childDossier.pension_fund_history[0] ? childDossier.pension_fund_history[0].origin : ""}}
{{childDossier.pension_type ? "" + childDossier.pension_type.replace(/^./, childDossier.pension_type[0].toUpperCase()).replace('hooglaag', 'Hoog/Laag') : ""}}</pre>
                        </td>
                        <td>{{formatDate(childDossier.created_at,'shortDate')}}</td>
                        <td>
                          <a class="fa-stack" @click="deleteDossiers(childDossier.id, childDossier.dossier_number)">
                              <i class="fas fa-circle fa-stack-2x text-danger"></i>
                              <i class="fal fa-trash-alt fa-stack-1x fa-inverse"></i>
                          </a>
                        </td>
                      </tr>
                  </table>
                </div>
              </div>
            </div>
          </template>

        </section>
      </div>
    </div>
  </div>

</template>

<script>
  import userservice from '@/services/admin/UserService'
  import dossierservice from '@/services/admin/DossierService'
  import Quotes from "@/components/admin/dossier/Quotes"
  import Documents from "@/components/admin/dossier/Documents"
  import QuickLinks from "@/components/admin/dossier/QuickLinks"
  import ContactSteps from "@/components/admin/dossier/ContactSteps"
  import Settlement from "@/components/admin/dossier/Settlement"
  //import ContactActivities from "@/components/admin/dossier/ContactActivities"
  import Accordion from "@/components/Accordion"
  import loginservice from '@/services/admin/LoginService'
  import contactStore from '@/store/contact'

  export default {
    name: 'adminContacts',
    components: {
      Quotes,
      Documents,
      QuickLinks,
      ContactSteps,
      Settlement,
      //ContactActivities,
      Accordion
    },
    watch: {
      '$route.params.id': function (id) {
        if(id) this.getDossier();
      }
    },
    computed: {
      totalPension: function(){
        let total = [];
        Object.entries(this.dossier.pension_fund_history).forEach(([key, val]) => {
            total.push(val.fund)
        });
        return total.reduce(function(total, num){ return parseFloat(total) + parseFloat(num) }, 0);
      },
      selectedQuote() {
          return this.dossier.quotes.filter((q) => {
              if (q.selected && q.active) {
                  return true
              }
          })[0]
      }
    },
    data() {
      return {
        dossier: {
          contact: {
            initials: null,
            prefix: null,
            last_name: null,
            email: null,
            phone_number: null,
            gender: null,
            birth_date: null,
            additional_text_base: null,
            additional_text_costs: null,
          },
          quotes: [],
          source: {
            name: '',
            price: '',
          },
          source_charge_contact: true,
          user_id: null
        },
        dataLoaded: false,
        users: [{
          'id': 0,
          'name': '-'
        }],
        overgang: {
          'll': 'Langstlevende',
          'mv': 'Medeverzekerde'
        },
        fileCategories: {
          'bankIdentification': 'Bankidentificatie',
          'identification': 'Legitimatiebewijs aanvrager',
          'identificationPartner': 'Legitimatiebewijs partner',
          'oldPolis': 'Oude polissen',
          'full_document': 'Compleet document'
        },
        fileDownloadNames: {
          'bankIdentification': 'bankidentificatie',
          'identification': 'legitimatiebewijs_aanvrager',
          'identificationPartner': 'legitimatiebewijs_partner',
          'oldPolis': 'oude_polissen',
          'full_document': 'Compleet document'
        },
        send_invoice: false,
        send_afstand: false,
        send_verzoek: true,
        send_aanvraag: true,
        send_quotation: false,
        send_company_quotation: true,
        isSyncing: false,
        showSettlement: false
      }
    },
    methods: {
      getDossier() {
        this.dataLoaded = false
        dossierservice.show(this.$route.params.id).then(response => {
          this.dossier = response.data
          if (this.dossier.invoice_id) {
            this.send_invoice = true
          }
          if (this.dossier.partner && this.dossier.partner.last_name != null && this.dossier.partner.insure_partner_pension == 0) {
            this.send_afstand = true
          }
          if((this.dossier.source.code == "befrank" || this.dossier.source.code == "BFR") && this.dossier.created_at > "2024-06-01"){
            this.showSettlement = true;
          }

          this.fileCategories.identification = 'Legitimatiebewijs aanvrager (' + this.dossier.contact.full_name + ')';
          this.fileCategories.identificationPartner = 'Legitimatiebewijs partner';
          if (this.dossier.partner && this.dossier.partner.full_name) {
            this.fileCategories.identificationPartner = this.fileCategories.identificationPartner + ' (' + this.dossier.partner.full_name + ')';
          }
          this.sortQuotes()
          if(!this.dataLoaded){
            if(!this.dossier.user_id){
              document.querySelector('button[aria-controls="collapse-1"]').click();
            }
          }
          if(this.dossier.pension_fund_history){
            this.dossier.pension_fund_history.forEach(fund => fund.selected = 0);
          }
          if(this.dossier.files){
            this.dossier.files.forEach(file => file.selected = file.content_type == 'full_document' ? 1 : 0);
            this.dossier.files.sort((a, b) => {
              if(a.type == 'bankIdentification'){
                return 1;
              }
              if(b.type == 'bankIdentification'){
                return -1;
              }
              if(a.type == 'identification'){
                return -1;
              }
              if(b.type == 'identification'){
                return 1;
              }
              return 1;
            });
          }
          this.dataLoaded = true
          this.$nextTick(() => {
            const el = document.querySelector("#heading-6 > button");
            if(el) el.click();
          })
        }).catch(error => {
          console.log('error', error) // create notification-toaster for user error
          this.$parent.$toast.error('Dossier niet gevonden');
          this.$router.push({
            name: "adminDossiers"
          });
        })
      },
      getUsers() {
        userservice.index().then(response => {
          this.users = response.data
        }).catch(error => {
          this.$toast.error('Failed to retreive data')
        })
      },
      approveDossier(e) {
        if (confirm('Weet u zeker dat u de offerte wilt e-mailen naar ' + this.dossier.contact.email + '?')) {
          this.setButtonLoadingIcon(e.target, true);
          dossierservice.approve(this.dossier.id).then(response => {
            this.$toast.success('E-mail is verstuurd.')
            this.getDossier()
          }).catch(error => {
            this.$toast.error('Fout bij het verzenden van de e-mail')
          });
        }
      },
      editRequest() {
        dossierservice.createExternalDataToken(this.dossier.id).then(response => {
          window.open("https://www.pensioenkoers.nl/berekenen?externalToken=" + response.data, '_blank').focus();
        }).catch(error => {
          this.$toast.error('Failed to create external data link')
        })
      },
      coupleUserToDossier() {
        userservice.coupleToDossier(this.dossier.user_id, this.dossier.id).then(response => {
          this.dossier.user = {id: this.dossier.user_id};
          this.$toast.success('Adviseur is toegewezen')
          this.next(1);
        }).catch(error => {
          this.$toast.error('Fout bij toewijzen van adviseur')
        })
      },
      loginAsContact() {
        loginservice.contactLogin(this.dossier.contact.id).then(response => {
          const route = process.env.VUE_APP_CLIENT_URL + '?ulogin=true&email='+encodeURIComponent(response.data.email)+'&name='+encodeURIComponent(response.data.name)+'&role=contact'+'&token='+encodeURIComponent(response.data.token)
          window.open(route, '_blank')
        }).catch(error => {
          console.log(error)
          this.$toast.error('Fout bij inloggen')
        })

      },
      downloadGeneratedInvoice() {
        dossierservice
          .generateInvoice(this.dossier.id)
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", this.setDownloadFileName(this.dossier.dossier_number, this.dossier.contact.full_name, 'Factuur #' + this.dossier.invoice.invoice_number + " - factuur van Apple Tree"));
            document.body.appendChild(link);
            link.click();
          })
          .catch((error) => {
            this.$toast.error("Error while attempting to download pdf");
          });
      },
      downloadGeneratedOfferte(e) {
        this.setButtonLoadingIcon(e.target, true);
        dossierservice
          .generateQuotation(this.dossier.id)
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            var requestType = (this.dossier.pension_type == 'Gelijkblijvend' || this.dossier.pension_type == 'hooglaag') ? "pensioen" : "lijfrente";
            link.href = url;
            const d = this.dossier;
            const filename = this.setDownloadFileName(d.dossier_number, d.contact.full_name, 'offerte van Apple Tree');
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();
            this.setButtonLoadingIcon(e.target, false);
          })
          .catch((error) => {
            this.$toast.error("Error while attempting to download pdf");
            console.log(error);
          });
      },
      downloadGeneratedSigned(type, fundname = '', fundId = '') {
        dossierservice
          .generateSigned(this.dossier.id, type, fundId)
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            var rtype = type;
            if(type == 'aanvraag_formulier'){
              rtype = 'aanvraagformulier_ondertekend';
            }
            if(type == 'verzoek_tot_overmaken'){
              rtype = 'verzoek_tot_overmaken_' + fundname;
            }
            link.href = url;
            link.setAttribute("download", this.setDownloadFileName(this.dossier.dossier_number, this.dossier.contact.full_name, rtype));
            document.body.appendChild(link);
            link.click();
          })
          .catch((error) => {
            this.$toast.error("Error while attempting to download pdf");
          });
      },
      downloadUserUploadedFile(fileId, type, extension, downloadNameOverride = false) {
        dossierservice
          .downloadFile(this.dossier.id, fileId)
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            let downloadName = this.fileDownloadNames[type];
            if (downloadNameOverride) {
              downloadName = downloadNameOverride
            }
            link.setAttribute("download", this.setDownloadFileName(this.dossier.dossier_number, this.dossier.contact.full_name, downloadName, extension));
            document.body.appendChild(link);
            link.click();
          })
          .catch((error) => {
            this.$toast.error("Error while attempting to download pdf");
          });
      },
      downloadQuoteFile(id, fileName) {
          dossierservice.downloadQuoteFile(id).then(response => {
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', fileName);
              document.body.appendChild(link);
              link.click();
          }).catch(error => {
              this.$toast.error('Failed to download file')
          })
      },
      setTitle(gender){
        return gender == 'male' ? 'Dhr. ' : 'Mevr. ';
      },
      calcAge(birthDate){
        let bdate = new Date(birthDate);
        let now = new Date();
        let age = now.getFullYear() - bdate.getFullYear();
        let monthDiff = now.getMonth() - bdate.getMonth();
        if(monthDiff < 0 || (monthDiff === 0 && now.getDate() < bdate.getDate())) age--;
        return '(' + age + ' jaar)';
      },
      next(step){
        document.querySelector('button[aria-controls="collapse-'+step+'"]').classList.add('stay-open');
        const accToOpen = document.querySelector('button[aria-controls="collapse-'+(step + 1)+'"]');
        if(accToOpen.classList.contains('collapsed')) accToOpen.click();
      },
      sortQuotes(item = null){
        let array = item == null ? this.dossier.quotes : item;
        array.sort( function(a, b){
          const pa = Number(a.payment);
          const pb = Number(b.payment);
          const psa = Number(a.payment_special_offer);
          const psb = Number(b.payment_special_offer);
          const maxA = a.payment_special_offer != null ? psa : pa
          const maxB = b.payment_special_offer != null ? psb : pb
          if (maxA > maxB) return -1;
          if (maxA < maxB) return 1;
          return 0;
        });
        return array;
      },
      exportEfficy(){
        var filesToExport = [];
        this.isSyncing = true;
        this.dossier.files.filter(file => file.selected).forEach(file => {
          filesToExport.push({
            "type": "file",
            "name": this.setDownloadFileName(this.dossier.dossier_number, this.dossier.contact.full_name, this.fileDownloadNames[file.content_type], file.file_type),
            "file_id": file.id,
          })
        });
        if(this.send_aanvraag){
          filesToExport.push({
            "type": "aanvraag_formulier",
            "name": this.setDownloadFileName(this.dossier.dossier_number, this.dossier.contact.full_name, 'aanvraag_formulier')
          })
        }
        if(this.send_afstand){
          filesToExport.push({
            "type": "afstandsverklaring",
            "name": this.setDownloadFileName(this.dossier.dossier_number, this.dossier.contact.full_name, 'afstandsverklaring')
          })
        }
        if(this.send_invoice){
          filesToExport.push({
            "type": "factuur",
            "name": this.setDownloadFileName(this.dossier.dossier_number, this.dossier.contact.full_name, 'Factuur #' + this.dossier.invoice.invoice_number + " - factuur van Apple Tree")
          })
        }
        this.dossier.pension_fund_history.filter(fund => fund.selected).forEach(fund => {
          filesToExport.push({
            "type": "verzoek_tot_overmaken",
            "name": this.setDownloadFileName(this.dossier.dossier_number, this.dossier.contact.full_name, 'verzoek tot overmaken ' + fund.pension_company.name),
            "fund_id": fund.id
          })
        })
        if(this.send_quotation){
          filesToExport.push({
            "type": "offerte",
            "name": this.setDownloadFileName(this.dossier.dossier_number, this.dossier.contact.full_name, 'offerte van Apple Tree')
          })
        }
        if(this.send_company_quotation){
          filesToExport.push({
            "type": "maatschappij_offerte",
            "name": this.setDownloadFileName(this.dossier.dossier_number, this.dossier.contact.full_name, this.selectedQuote.provider + " offerte"),
            "quote": this.selectedQuote,
          })
        }
        if(filesToExport.length == 0) return;
        dossierservice.syncFilesEfficy(this.dossier.id, {'export_files': filesToExport})
          .then((response) => {
            this.$toast.success("Bestanden zijn gesynchroniseerd");
            this.isSyncing = false;
          })
          .catch((error) => {
            this.$toast.error("Error while attempting to sync");
            this.isSyncing = false;
          });
      },
      updateDossier() {
        dossierservice.update(this.dossier.id, this.dossier).then(response => {
          this.$toast.success('Update is opgeslagen')
          this.dossier.contact.efficy_K_CONTACT = response.data.contact.efficy_K_CONTACT
          this.dossier.efficy_K_PROJECT = response.data.efficy_K_PROJECT
        }).catch(error => {
          console.log('error', error) // create notification-toaster for user error
        })
      },
      totalOtherPension(pensionFundHistory){
        let total = [];
        Object.entries(pensionFundHistory).forEach(([key, val]) => {
            total.push(val.fund)
        });
        return total.reduce(function(total, num){ return parseFloat(total) + parseFloat(num) }, 0);
      },
      deleteDossiers(id, number) {
        if (confirm('Weet je zeker dat je ' + number + ' dossier wilt archiveren?')) {
          dossierservice.delete(id).then(response => {
            this.getDossier()
            this.$toast.success('dossier is gearchiveerd')
          }).catch(error => {
            this.$toast.error('fout bij archiveren')
          })
        }
      }
    },
    mounted() {
      this.getDossier()
      this.getUsers()
    }
  }
</script>

<style scoped>
    .accordion {
      --bs-accordion-color: #212921;
      --bs-accordion-border-color: var(--bs-border-color);
      --bs-accordion-btn-color: #212921;
      --bs-accordion-btn-focus-border-color: #6aac5a;
      --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(27, 190, 12, 0.25);
      --bs-accordion-active-color: #107007;
      --bs-accordion-active-bg: #e9ffe7;
  }
  .mt-4.accordion-item{
      border-top: 1px solid var(--bs-border-color);
  }

  #relatedTable {
    width: 100%;
  }
  
  #relatedTable th, #relatedTable td {
    width: auto;
  }

  .current-dossier td {
    font-weight: bold;
  }
  #relatedTable a {
    padding: 0px;
  }
</style>