import {ApiService} from '@/services/ApiService'
import http from '@/http-common'

const URL = '/api/admin/user'

class UserService extends ApiService{
  constructor(){
    super()
  }
  getUrl(){
      return URL
  }
  getRoles() {
    return http.get(`${URL}/roles`)
  }
  coupleToDossier(userId, dossierId) {
    return http.post(`${URL}/${userId}/dossier/${dossierId}`)
  }
}

export default new UserService()