<template>
        <div class="mt-4 accordion-item">
            <div class="accordion-header" :id="'heading-'+ this.index">
                <button class="acc-header-item header-main accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    :data-bs-target="'#collapse-'+ this.index" aria-expanded="false" :aria-controls="'collapse-'+ this.index"
                    @click.prevent="collapseOthers()">

                    <slot name="header"></slot>

                </button>
            </div>
            <div v-if="stayOpen" class="accordion-collapse collapse" :id="'collapse-'+ this.index" :aria-labelledby="'heading-'+ this.index">
                <div class="accordion-body">
                    <slot name="body"></slot>
                </div>
            </div>
            <div v-else class="accordion-collapse collapse" :id="'collapse-'+ this.index" :aria-labelledby="'heading-'+ this.index"
                data-bs-parent="contract-accordion">
                <div class="accordion-body">
                    <slot name="body"></slot>
                </div>
            </div>
        </div>
</template>
<script>
  export default {
    name: 'Accordion',
    props: {
        'index': {},
        'expanded': {default: false},
        'stayOpen': {default: true},
    },
    methods: {
        collapseOthers(){
          if(this.stayOpen) return;
          let i = 0;
          document.querySelectorAll(".header-main").forEach(el => {
              if (i != this.index && el.getAttribute("aria-expanded") == "true" && !el.classList.contains('stay-open')) el.click();
              i++;
          })
      },
    }
  }
</script>

<style scoped>
    .accordion-header{
        padding: 0px;
    }
</style>